import React, { useEffect } from 'react';
import { Route, BrowserRouter } from 'react-router-dom';
import MultipleQuestionPage from './main/components/Pages/MultipleQuestionPageTemplate/MultipleQuestionPageTemplate';
import './App.css';
import FlexiblePageData from "./FlexiblePageData.json";
import ErrorPage from './main/components/GenericComponents/ErrorPage/ErrorPage';
import { checkRoute } from './main/utils/pageUtil';
import { acceptGoogleAnalytics, getCookiesPolicy, rejectGoogleAnalytics } from './main/utils/cookieUtil/cookieUtil';
import AccessibilityPage from './main/components/Pages/AccessibilityPage/AccessibilityPage';

const pages = FlexiblePageData.pages;

const googleAnalytics = async () => {
    if (getCookiesPolicy() === "true") {
        acceptGoogleAnalytics();
    } else {
        rejectGoogleAnalytics();
    }
}

const App = () => {
    
    useEffect(()=> {
       googleAnalytics();
    }, []);

    return (
        <BrowserRouter>
            <Route
                exact
                path="/accessibility-statement"
                render={() => <AccessibilityPage/>}
            />
            {
                !checkRoute(pages) ? <ErrorPage /> : 
                Object.keys(pages).map((key) => {
                    const option = pages[key]
                    if (option.type === "multiquestion") {
                        return (
                            <Route
                                exact
                                key={option.url}
                                path={option.url}
                                render={() => <MultipleQuestionPage pageKey={key} pageData={FlexiblePageData} />}
                            />
                        );
                    } else {
                        return <div>Something went wrong</div>
                    }
                })
            }

        </BrowserRouter>
    );
}

export const exportsForTesting = {
    googleAnalytics,
}

export default App;
