import mappingUtil from '../mappingUtil/mappingUtil';

const DWO_URL = '/api';
const ORG_SEARCH_URL = '/search';

export const orgManagerSearch = async (searchTerm) => {
  const encodeSearchTerm = encodeURIComponent(searchTerm).replace('.', '%2E');
  const response = await fetch(`${ORG_SEARCH_URL}?orgSearch=${encodeSearchTerm}`);
  const json = await response.json();
  return { status: response.status, statusText: response.statusText, data: json };
};

export const fetchVariable = async (key) => (
  fetch(`/fetchVariable?${new URLSearchParams({ key })}`)
);

export default function submitFormRequest(recaptcha) {
  const incidentForm = mappingUtil();
  return fetch(DWO_URL, {
    method: 'post',
    body: JSON.stringify({ incidentForm, recaptcha }),
    headers: { 'Content-Type': 'application/json' },
  });
}
