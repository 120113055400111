import React from 'react';
import PropTypes from 'prop-types';
import CustomTable, { propTypes as customTableProps } from '../Custom/CustomTable';

const getSelectedCheckboxesFromSession = (sessionKey) => {
  const sessionValues = JSON.parse(sessionStorage.getItem(sessionKey));
  return Object.keys(sessionValues).filter((v) => sessionValues[v]);
};

function MultiTable(props) {
  const { sessionKey, rows } = props;
  const selectedCheckboxes = getSelectedCheckboxesFromSession(sessionKey);

  return Object.keys(rows)
    .filter((filterRow) => selectedCheckboxes.indexOf(filterRow) > -1)
    .map((type) => rows[type])
    .map((row) => (
      <div key={row[0].decider.title}>
        <br />
        <b><p>{row[0].decider.title}</p></b>
        <CustomTable rows={row} />
      </div>
    ));
}

MultiTable.propTypes = {
  sessionkey: PropTypes.string,
  rows: PropTypes.objectOf(customTableProps.rows),

};

export const exportForTesting = {
  getSelectedCheckboxesFromSession,
};
export default MultiTable;
