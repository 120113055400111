import React from 'react';
import { bool, number, string } from 'prop-types';

const headerSize = {
  1: 'h1',
  2: 'h2',
  3: 'h3',
  4: 'h4',
  5: 'h5',
  6: 'h6',
};

function Header({ text, size, bold }) {
  const HeaderSize = headerSize[size];

  return (
    <HeaderSize style={{ fontWeight: bold && 'bold', fontSize: '20px', marginTop: '1.5%' }}>
      {text}
    </HeaderSize>
  );
}

Header.propTypes = {
  text: string.isRequired,
  size: number.isRequired,
  bold: bool || undefined,
};

Header.defaultProps = {
  bold: undefined,
};

export default Header;
