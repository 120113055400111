import React from 'react';
import PropTypes from 'prop-types';
import { setInnerHtml } from '../../../../utils/pageUtil';
// eslint-disable-next-line import/no-cycle
import ComponentMap from '../../ComponentMap/ComponentMap';
import './Information.css';

function Information({ text }) {
  return (
    typeof text !== 'string'
      ? <ComponentMap components={text} />
      : <p>{setInnerHtml(text)}</p>
  );
}

Information.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Information;
