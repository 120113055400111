import React from 'react';
import Wrapper from '../Wrapper/Wrapper';
import Information from '../PageComponents/Information/Information';
import Title from '../PageComponents/Title/Title';
import Header from '../PageComponents/Header/Header';
import BulletPoints from '../PageComponents/BulletPoints/BulletPoints';

export default function ErrorPage() {
  return (

    <Wrapper
      pageTitle="Error"
      title="Error"
    >
      <div>
        <h1 style={{ color: '#303030 ', fontWeight: 'bold' }}>Error 404</h1>
        <Title title="Page not found" />
        <div style={{ marginTop: '30px' }} />

        <Information text="We are unable to find the page you requested." />

        <div style={{ marginTop: '30px' }} />

        <Header text="Please try the following options:" size={2} />

        <BulletPoints bulletPoints={{
          points: [
            'Check the URL to make sure there were no typing or copy-and-paste errors',
            `If you still can't see what you're looking for, try our <html><a href='${window.location.origin}'>Start page</a><html> or our <html><a href='https://www.ncsc.gov.uk/'>Homepage</a><html>`,
            "If you think there might be a problem with our service, please contact <html><a href='mailto:incidents@ncsc.gov.uk'>incidents@ncsc.gov.uk</a><html>",
          ],
        }}
        />
      </div>

    </Wrapper>
  );
}
