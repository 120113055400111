import React from 'react';
import { CheckBoxGroup as UuiCheckBoxGroup } from '@ukncsc/my-ncsc-ui-common-core';
import {
  arrayOf, bool, func, objectOf, shape, string,
} from 'prop-types';
import { objectEqualityChecker } from '../../../../utils/pageUtil';
import './CheckBoxGroup.css';

function toJson(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return false;
  }
}

function CheckBoxGroup({
  id, storageId, options, values, setValues, hasError, errorMessage,
}) {
  const sessionCheckBoxValues = toJson(values[storageId]) || {};

  const checkBoxData = options.map(({ id: checkboxId, value, text }) => ({
    id: checkboxId,
    label: text,
    value,
    checked: sessionCheckBoxValues[value],
  }));

  const handleChange = (checkData) => {
    const checked = {};
    checkData.forEach((data) => {
      checked[data.value] = data.checked || false;
    });

    if (!objectEqualityChecker(JSON.stringify(checked), values[storageId])) {
      setValues({ ...values, [storageId]: JSON.stringify(checked) });
    }
  };
  return (
    <div className={hasError ? 'error' : ''} style={{ marginBottom: '1.5%' }}>
      {hasError && <span className="error_span" role="alert">{errorMessage}</span>}

      <UuiCheckBoxGroup
        checkData={checkBoxData}
        name={storageId}
        key={id}
        className={`focused ${hasError && 'error_margin'}`}
        handleChange={handleChange}
      />

    </div>
  );
}

CheckBoxGroup.propTypes = {
  id: string.isRequired,
  storageId: string.isRequired,
  options: arrayOf(shape({
    value: string,
    text: string,
    id: string,
  })).isRequired,
  values: objectOf(string).isRequired,
  setValues: func.isRequired,
  hasError: bool,
  errorMessage: string.isRequired,
};

CheckBoxGroup.defaultProps = {
  hasError: false,
};

export const exportsForTesting = {
  toJson,
};

export default CheckBoxGroup;
