import { dynamicContent } from '../pageUtil';
import validationReg from './validationReg';

const isItJson = (item) => {
  let parsed;
  try {
    parsed = JSON.parse(item);
  } catch (e) {
    return false;
  }
  return typeof parsed !== 'number';
};

const orgManagerValidation = (values, { orgStorageId, maxLength }) => (
  values[orgStorageId] && (maxLength >= values[orgStorageId].length)
);

// updates displayed details with errors/removes errors if necessary
const updateDetailsWithError = (displayedDetails, index, setDisplayDetails, didError) => {
  const newDisplayedDetails = { ...displayedDetails };
  newDisplayedDetails.components[index].component.hasError = didError;
  setDisplayDetails({ ...newDisplayedDetails });
};

// validation method for required values
const requiredValidation = (
  value,
  displayedDetails,
  index,
  setDisplayDetails,
  regex,
  maxLength,
  errors,
) => {
  // if required checkbox
  if (isItJson(value)) {
    const jsonVals = JSON.parse(value);
    let selected = false;
    Object.keys(jsonVals).forEach((jsonIndex) => {
      if (jsonVals[jsonIndex]) {
        selected = true;
      }
    });

    // if nothing is selected show error in ui
    if (!selected) {
      updateDetailsWithError(displayedDetails, index, setDisplayDetails, true);
      return true;
    }
    updateDetailsWithError(displayedDetails, index, setDisplayDetails, false);
  } else if (typeof value === 'object') {
    updateDetailsWithError(displayedDetails, index, setDisplayDetails, false);
  // if a string, check possible unselected/empty/failed validation
  } else if (
    value === ''
    || !value.match(regex)
    || !value
    || value === 'Please select an option'
    || (maxLength && value.length > maxLength)
  ) {
    updateDetailsWithError(displayedDetails, index, setDisplayDetails, true);
    return true;
  } else {
    updateDetailsWithError(displayedDetails, index, setDisplayDetails, false);
  }
  return errors;
};

// contains logic to decide whether to error the org manager component or not
const orgManagerValidator = (
  values,
  displayedDetail,
  displayedDetails,
  index,
  setDisplayDetails,
  errors,
) => {
  if (orgManagerValidation(values, displayedDetail.component)) {
    updateDetailsWithError(displayedDetails, index, setDisplayDetails, false);
  } else if (dynamicContent(displayedDetail.criteria, values)) {
    /* This else if is used to check that the org manager is shown to user, if this
    was just in the else it would cause 3rd party org name to error when user is not 3rd party
    */
    updateDetailsWithError(displayedDetails, index, setDisplayDetails, true);
    return true;
  } else {
    updateDetailsWithError(displayedDetails, index, setDisplayDetails, true);
  }
  return errors;
};

// validation for non-required fields that have had data entered into them
const notRequiredValidation = (
  value,
  displayedDetails,
  index,
  setDisplayDetails,
  regex,
  maxLength,
  errors,
) => {
  // if checkboxes or empty and unrequired
  if (typeof value === 'object' || value === '' || !value || isItJson(value)) {
    updateDetailsWithError(displayedDetails, index, setDisplayDetails, false);
    // checking for any validation issues with strings
  } else if ((!value.match(regex) && value !== '') || value.length > maxLength) {
    updateDetailsWithError(displayedDetails, index, setDisplayDetails, true);
    return true;
    // we're happy at this point
  } else {
    updateDetailsWithError(displayedDetails, index, setDisplayDetails, false);
  }
  return errors;
};

// main validation method for all values
const validation = (displayedDetails, values, setDisplayDetails) => {
  let errors = false;
  if (displayedDetails !== null && displayedDetails !== undefined) {
    displayedDetails.components.forEach((displayedDetail, index) => {
      // if criteria is display and storageID does not = undefined
      if (dynamicContent(displayedDetail.criteria, { ...sessionStorage, ...values })
        && (displayedDetail.component.storageId !== undefined)) {
        const {
          regex, required, maxLength, validationType,
        } = displayedDetail.component;
        const regexObject = validationReg(regex, validationType);
        const value = values[displayedDetail.component.key];
        // check if the variable is required to be validated
        if (required === true) {
          errors = requiredValidation(
            value,
            displayedDetails,
            index,
            setDisplayDetails,
            regexObject,
            maxLength,
            errors,
          );
        } else {
          // validation for non-required fields which the user has entered data into
          errors = notRequiredValidation(
            value,
            displayedDetails,
            index,
            setDisplayDetails,
            regexObject,
            maxLength,
            errors,
          );
        }
        // org manager has different variables and a different validation process
      } else if (displayedDetail.component.type === 'org_manager') {
        errors = orgManagerValidator(
          values,
          displayedDetail,
          displayedDetails,
          index,
          setDisplayDetails,
          errors,
        );
      }
    });
  }
  return errors;
};

export const exportsForTesting = {
  orgManagerValidation,
  updateDetailsWithError,
  isItJson,
  requiredValidation,
  orgManagerValidator,
  notRequiredValidation,
};

export default validation;
