import React from 'react';
import { RadioButton } from '@ukncsc/my-ncsc-ui-common-core';
import '../hasError.css';
import {
  string,
  arrayOf,
  shape,
  objectOf,
  func,
  bool,
} from 'prop-types';

function RadioButtons({
  id, storageId, options, values, setValues, direction, hasError, errorMessage, legend,
}) {
  return (
    <div
      className={hasError ? 'error' : ''}
      style={{ marginBottom: '2.5%' }}
    >
      {hasError && <span className="error_span" role="alert">{errorMessage}</span>}
      <div
        className={`radio-container radio-${direction || 'vertical'}`}
        style={{ marginLeft: hasError && '1.5%' }}
      >
        <fieldset style={{ marginLeft: hasError && '1.5%' }}>
          <legend hidden>{legend}</legend>
          {
            options.map((option) => {
              if (typeof option !== 'string') {
                return (
                  <RadioButton
                    key={`${storageId}_${option.id}`}
                    value={`${id}_${option.id}`}
                    onChange={() => {
                      setValues({ ...values, [storageId]: option.text });
                    }}
                    checked={values[storageId] === option.text}
                    className="largeButton"
                    aria-checked={values[storageId] === option.text}
                    name={id}
                    label={option.text}
                  />
                );
              }
              return null;
            })
            }
        </fieldset>
      </div>
    </div>
  );
}

RadioButtons.propTypes = {
  id: string.isRequired,
  options: arrayOf(shape({
    value: string,
    id: string,
    text: string,
    label: string,
  })).isRequired,
  legend: string,
  storageId: string.isRequired,
  values: objectOf(string).isRequired,
  setValues: func.isRequired,
  hasError: bool,
  errorMessage: string.isRequired,
  direction: string,
};

RadioButtons.defaultProps = {
  legend: undefined,
  hasError: undefined,
  direction: undefined,
};

export default RadioButtons;
