import { string } from 'prop-types';
import React from 'react';

function Title({ title }) {
  return (
    <h2 style={{
      fontWeight: '700', fontSize: '32px', marginTop: '1%', lineHeight: '1.5',
    }}
    >
      {title}
    </h2>
  );
}

Title.propTypes = {
  title: string.isRequired,
};

export default Title;
