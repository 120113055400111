import React, { useEffect, useState } from 'react';
import { fetchVariable } from '../../../../utils/apiUtil/apiUtil';

export async function getSpUrl(setSpUrl) {
  await fetchVariable('spUrl').then(async (response) => {
    if (response.ok) {
      setSpUrl(await response.text());
    }
  });
}

function SignpostingBanner() {
  const refererr = document.referrer;
  const [spUrl, setSpUrl] = useState(null);
  useEffect(() => {
    getSpUrl(setSpUrl);
  }, []);
  if (!refererr.includes(spUrl)) {
    return (
      <div className="card info">
        <p style={{ fontWeight: 'bold' }}>Cyber Incident Signposting Service (CISS)</p>
        <p>
          You may be required to notify multiple organisations as different
          organisations have different remits.
          If you are unsure who to report to, please use this
          {' '}
          <a href={`https://${spUrl}`}> Cyber Incident Signposting Service (CISS)</a>
          {' '}
          for guidance.
          {' '}
        </p>

      </div>
    );
  }

  return null;
}

export default SignpostingBanner;
