import React, { useState } from 'react';
import { TextInput } from '@ukncsc/my-ncsc-ui-common-core';
import {
  arrayOf,
  bool, func, number, objectOf, string,
} from 'prop-types';
import '../hasError.css';
import './TextBox.css';

const validation = (value, reg, maxLen, setCharacterLimit, required) => {
  if (value.length === maxLen) {
    setCharacterLimit(true);
  } else {
    setCharacterLimit(false);
    if (value === '' && required) {
      return true;
    }
    if (!value.match(reg)) {
      return true;
    }
    if (value.length > maxLen) {
      return true;
    }
  }
  return false;
};

function TextBox({
  id, storageId, values, setValues, maxLength = 100, label, helpText, hasError, errorMessage,
  regex, required, autocompleteType,
}) {
  const [error, setError] = useState(null);
  const [characterLimit, setCharacterLimit] = useState(null);

  return (
    <div className={(hasError || characterLimit) || error ? 'error' : null}>
      <div className="textBox">
        <label htmlFor={id} className={(hasError || characterLimit) || error ? 'error_margin' : null}>{label}</label>
        <br />
        <p className={hasError || error ? 'helpText helpTextError' : 'helpText'}>{helpText}</p>
        {hasError || error ? <span className="error_span" role="alert">{errorMessage}</span> : null}
        {characterLimit && (
          <span className="error_span" role="alert">You have reached the limit for characters in this field</span>
        )}
        <TextInput
          id={autocompleteType || id}
          key={id}
          name={autocompleteType || storageId}
          handleChange={(e) => {
            const valid = validation(e, regex, maxLength, setCharacterLimit, required);
            if (valid) {
              setError(true);
            } else {
              setError(false);
            }
            setValues({ ...values, [storageId]: e });
          }}
          autoComplete="on"
          maxLength={maxLength}
          hideCharacterCount
          value={values[storageId]}
          className={(hasError || characterLimit) && 'error_margin'}
          pattern=".*"
        />
      </div>
    </div>
  );
}

TextBox.propTypes = {
  id: string.isRequired,
  storageId: string.isRequired,
  values: objectOf(string).isRequired,
  setValues: func.isRequired,
  hasError: bool,
  errorMessage: string.isRequired,
  maxLength: number,
  label: string,
  helpText: string,
  regex: arrayOf(string).isRequired,
  required: bool,
  autocompleteType: string || undefined,
};

TextBox.defaultProps = {
  hasError: false,
  maxLength: 100,
  label: '',
  helpText: '',
  required: false,
  autocompleteType: undefined,
};

export const exportForTesting = { validation };
export default TextBox;
