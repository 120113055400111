import { string } from 'prop-types';
import React from 'react';
import { replaceVariablesNamesInString } from '../../../../utils/sessionUtil/sessionUtil';
import './ReferenceNumber.css';

function ReferenceNumber({ input }) {
  return (
    <p className="ref">
      Ref:
      {' '}
      {replaceVariablesNamesInString(input)}
    </p>
  );
}

ReferenceNumber.propTypes = {
  input: string || undefined,
};

ReferenceNumber.defaultProps = {
  input: undefined,
};

export default ReferenceNumber;
