import React, { useState, useEffect } from 'react';
import { fetchVariable } from '../../../../utils/apiUtil/apiUtil';

export async function getFeedbackUrl(setFeedbackUrl) {
  await fetchVariable('feedbackUrl').then(async (response) => {
    if (response.ok) {
      setFeedbackUrl(await response.text());
    }
  });
}

function Feedback() {
  const [feedbackUrl, setFeedbackUrl] = useState('');

  useEffect(() => {
    getFeedbackUrl(setFeedbackUrl);
  }, []);

  return (
    <p>
      Your
      {' '}
      <a href={feedbackUrl} target="_blank" rel="noreferrer">feedback</a>
      {' '}
      will help us to improve this service.
    </p>
  );
}

export default Feedback;
