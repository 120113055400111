import {
  arrayOf, bool, shape, string,
} from 'prop-types';
import React from 'react';
import { setInnerHtml } from '../../../../utils/pageUtil';

function BulletPoints({ bulletPoints }) {
  const mapPoints = (bullets) => (
    <>
      {bullets.points.map(
        (value) => (
          <li
            key={value}
          >
            {setInnerHtml(value)}
          </li>
        ),
      )}
    </>
  );

  return (
    <div>
      {bulletPoints.header && <p>{bulletPoints.header}</p>}
      {bulletPoints.ordered
        ? (
          <ol>
            {mapPoints(bulletPoints)}
          </ol>
        )
        : (
          <ul>
            {mapPoints(bulletPoints)}
          </ul>
        )}
    </div>
  );
}

BulletPoints.propTypes = {
  bulletPoints: shape({
    header: string,
    ordered: bool,
    points: arrayOf(string),
  }).isRequired,
};

export default BulletPoints;
