import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  string, object, objectOf, oneOfType,
} from 'prop-types';
import { Button } from '@ukncsc/my-ncsc-ui-common-core';
import Wrapper from '../../GenericComponents/Wrapper/Wrapper';
import Card from '../../GenericComponents/PageComponents/Card/Card';
import { dynamicContent, nextPageFinder, updateCompletedUrls } from '../../../utils/pageUtil';
import Title from '../../GenericComponents/PageComponents/Title/Title';
import DynamicComponentMap from '../../GenericComponents/DynamicComponentMap/DynamicComponentMap';
import validation from '../../../utils/validationUtil/validation';
import ReCaptcha from '../../GenericComponents/PageComponents/ReCaptcha/ReCaptcha';
import '../../GenericComponents/PageComponents/Card/Card.css';

const getOrgManagerIds = (component) => [
  component.orgStorageId,
  component.identifierStorageId,
];

const saveValues = (displayedDetails, values) => {
  Object.keys(values).forEach(
    (value) => {
      Object.values(displayedDetails.components).forEach(({ component, criteria }) => {
        if (
          // checking the storage id equals the value key
          ((component.storageId && component.storageId === value)
          // or the org manager ids match the value key
          || (component.type === 'org_manager' && getOrgManagerIds(component).includes(value)))
          && dynamicContent(criteria, { ...sessionStorage, ...values })
        ) {
          sessionStorage.setItem(value, values[value]);
        }
      });
    },
  );
};

function onSubmit(displayDetails, values, history, pageKey, setDisplayDetails, pageData, setError) {
  const errorState = validation(displayDetails, values, setDisplayDetails);
  setError(errorState);
  if (errorState === false) {
    const { pages } = pageData;
    saveValues(displayDetails, values);

    let nextPage = displayDetails.next;
    nextPage = nextPageFinder(nextPage, history);
    updateCompletedUrls(pageKey);
    history.push(pages[nextPage].url);
  }
}

// this gets the session storage keys for all input components on a page
const getStorageKeys = (pageDetails) => {
  const storageKeys = [];
  pageDetails.components.forEach(
    ({ component }) => {
      if (component.type === 'org_manager') {
        storageKeys.push(
          component.orgStorageId,
          component.identifierStorageId,
        );
      } else if (component.storageId) {
        storageKeys.push(component.storageId);
      }
    },
  );
  return storageKeys;
};

const getNextUrl = (pageData, displayedDetails, values) => {
  try {
    const nextPage = nextPageFinder(displayedDetails.next, values);
    return (nextPage && displayedDetails.next ? pageData.pages[nextPage].url : null);
  } catch (e) {
    return null;
  }
};

export default function MultipleQuestionPageTemplate({ pageKey, pageData }) {
  const history = useHistory();

  const pageDetails = pageData.pages[pageKey];

  const storageKeys = getStorageKeys(pageDetails);

  const initialValues = {};
  storageKeys.forEach((key) => {
    initialValues[key] = sessionStorage.getItem(key) || '';
  });

  const [values, setValues] = useState(initialValues);
  const [displayedDetails, setDisplayedDetails] = useState(
    pageDetails ? { ...pageDetails } : {},
  );
  const [hasError, setHasError] = useState(false);
  const [nextPage, setNextPage] = useState(getNextUrl(pageData, displayedDetails, values));

  useEffect(() => {
    setNextPage(getNextUrl(pageData, displayedDetails, values));
  }, [values]);

  useEffect(() => {
    document.getElementById('main-title').focus();
  }, []);

  return (
    <Wrapper
      pageTitle={displayedDetails.title}
      title={displayedDetails.title}
      history={history}
    >
      <span style={{
        color: '#555555', fontWeight: 700, fontSize: '20px', marginBottom: '10%',
      }}
      >
        {displayedDetails.section}
      </span>

      {displayedDetails.title && <Title title={displayedDetails.title} />}

      <DynamicComponentMap
        components={displayedDetails.components}
        pageId={pageKey}
        values={values}
        setValues={setValues}
        pageData={pageData}
      />

      <div style={{ marginTop: '5%' }}>
        {
          hasError && (
            <Card
              id="form-submit-error"
              header=""
              cardType="danger"
              value="There are errors with the answers provided on this page. Please check the form and try again."
            />
          )
        }
        {
          displayedDetails.showContinueButton && (
            <Button
              variant="dark"
              id="submit_login"
              onClick={() => onSubmit(
                displayedDetails,
                values,
                history,
                pageKey,
                setDisplayedDetails,
                pageData,
                setHasError,
              )}
              action={`${window.location.hostname}${nextPage}`}
              type="button"
              style={{ fontWeight: '800' }}
            >
              Continue
            </Button>
          )
        }
        {
          displayedDetails.showRecaptcha && (
            <ReCaptcha
              text="submit"
              currentPageId={pageKey}
              pageData={pageData}
            />
          )
        }
      </div>

    </Wrapper>
  );
}

MultipleQuestionPageTemplate.propTypes = {
  pageKey: string.isRequired,
  pageData: objectOf(
    oneOfType([
      string,
      object,
    ]),
  ).isRequired,
};

export const exportsForTesting = {
  onSubmit, saveValues, getStorageKeys,
};
