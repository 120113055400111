import React from 'react';
import { setInnerHtml } from '../../../../utils/pageUtil';
import './Card.css';

const cardDraw = (header, cardType, value) => (
  <div className={`card ${cardType}`}>
    {header && <h4 className="header">{header}</h4>}
    <p className="card_text">{setInnerHtml(value)}</p>
  </div>
);

const asteriskCardDraw = (value) => (
  <div className="card asterisk">
    <p className="card_text">{setInnerHtml(value)}</p>
  </div>
);

const Card = ({
  header, value, cardType,
}) => {
  let card;
  switch (cardType) {
    case 'info':
      card = cardDraw(header, cardType, value);
      break;
    case 'warning':
      card = cardDraw(header, cardType, value);
      break;
    case 'danger':
      card = cardDraw(header, cardType, value);
      break;
    case 'asterisk':
      card = asteriskCardDraw(value);
      break;
    default:
      card = cardDraw(header, value);
      break;
  }
  return card;
};

export default Card;
