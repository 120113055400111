/* eslint-disable react/jsx-filename-extension */
import React from 'react';

const objectEqualityChecker = (answer, value) => {
  let equality = true;
  if ((!value || !answer)) {
    return false;
  }

  Object.keys(answer).forEach((property) => {
    if (answer[property] !== value[property]) {
      equality = false;
    }
  });
  return equality;
};

const nextPageCheckbox = (nextPage, pageKey) => {
  const checkboxAnswers = nextPage[pageKey].checkbox;
  const pageValue = sessionStorage.getItem(pageKey);
  const parsedValue = JSON.parse(pageValue);
  let newNextPage = '';
  let nextPageSet = false;

  Object.keys(checkboxAnswers).forEach((index) => {
    if (typeof checkboxAnswers[index] === 'string' && nextPageSet === false) {
      newNextPage = checkboxAnswers[index];
    }
    const answer = Object.keys(checkboxAnswers[index])[0];
    const parsedAnswers = JSON.parse(answer);

    if (objectEqualityChecker(parsedAnswers, parsedValue) && nextPageSet === false) {
      newNextPage = checkboxAnswers[index][answer];
      // needed as a way to break the forEach setting the wrong next page
      nextPageSet = true;
    }
  });
  return newNextPage;
};

const nextPageRadio = (nextPage, pageKey, stateValues = {}) => {
  const pageValue = sessionStorage.getItem(pageKey) || stateValues[pageKey];
  let newNextPage = '';
  const nextPageValue = nextPage[pageKey][pageValue];
  if (pageValue === null || nextPageValue === null || nextPageValue === undefined) {
    newNextPage = nextPage[pageKey].default;
  } else {
    newNextPage = nextPage[pageKey][pageValue];
  }

  return newNextPage;
};

const nextPageFinder = (nextPage, stateValues = {}) => {
  let page = nextPage;
  while (typeof page !== 'string') {
    if (!page) {
      return null;
    }
    const pageKey = Object.keys(page)[0];
    if (page[pageKey].checkbox) {
      page = nextPageCheckbox(page, pageKey);
    } else {
      page = nextPageRadio(page, pageKey, stateValues);
    }
  }
  return page;
};

const checkboxRequirement = (values, sessionKey, requirement) => {
  // checkboxAnswers is a JSON object array of keys that should map to a ['display'] option,
  // or potentially another JSON object to recurse through.
  const checkboxAnswers = requirement[sessionKey].checkbox;
  // sessionValue is a JSON object containing keys and boolean values
  // for if the checkbox has been selected or not.
  let sessionValue;
  if (values[sessionKey]) {
    sessionValue = values[sessionKey];
  } else {
    sessionValue = sessionStorage.getItem(sessionKey);
  }
  let sessionValueJSON;
  if (typeof sessionValue === 'string') {
    sessionValueJSON = JSON.parse(sessionValue);
  } else {
    sessionValueJSON = sessionValue;
  }
  let newRequirement = '';

  checkboxAnswers.forEach((answerIndex) => {
    if (typeof answerIndex === 'string') {
      newRequirement = answerIndex;
      return;
    }
    const answer = Object.keys(answerIndex)[0];
    if (sessionValueJSON[answer] === true) {
      newRequirement = answerIndex[answer];
    }
  });

  return newRequirement;
};

const radioRequirement = (values, pageKey, requirement) => {
  let pageValue = '';
  if (values[pageKey]) {
    pageValue = values[pageKey];
  } else {
    pageValue = sessionStorage.getItem(pageKey);
    if (pageValue === null) {
      pageValue = false;
    }
  }
  return requirement[pageKey][pageValue];
};

const dynamicContent = (requirements, values) => {
  let display = false;
  if (!requirements || !values) {
    return false;
  }

  Object.keys(requirements).forEach((requirement) => {
    let req = requirements[requirement];
    while (typeof req !== 'string') {
      const pageKey = Object.keys(req)[0];
      if (req[pageKey].checkbox) {
        req = checkboxRequirement(values, pageKey, req);
      } else {
        req = radioRequirement(values, pageKey, req);
      }
      if (req === null || req === undefined) {
        break;
      }
    }
    if (req === 'display') {
      display = true;
    }
    return display;
  });
  // eslint-disable-next-line consistent-return
  return display;
};

const COMPLETED_PAGES_KEY = 'completed_pages';
export const updateCompletedUrls = (completedPage) => {
  const completedPages = JSON.parse(sessionStorage.getItem(COMPLETED_PAGES_KEY)) || {};
  completedPages[completedPage] = true;
  sessionStorage.setItem(COMPLETED_PAGES_KEY, JSON.stringify(completedPages));
};
const buildUserPagesRoute = (pageData) => {
  let completedPages = ['startpage'];
  const startPage = pageData.startpage;
  let page = startPage;
  let endOfUserRoute = false;
  if (window.location.pathname === pageData.startpage.url) {
    return [];
  }
  if (window.location.pathname === pageData.reportComplete.url) endOfUserRoute = true;
  while (!endOfUserRoute) {
    const nextPageId = nextPageFinder(page.next);
    const nextPage = pageData[nextPageId];
    // completedPages = completedPages.concat(nextPageId)
    if (!nextPage || nextPage.url === window.location.pathname) {
      endOfUserRoute = true;
    } else {
      completedPages = completedPages.concat(nextPageId);
      page = nextPage;
    }
  }
  return completedPages;
};

const checkRoute = (pageData) => {
  // when submitted successfully we clear session storage,
  // but if they refresh we don't want the page to break
  let notValid = false;
  if (sessionStorage.getItem('form_submitted_status') === 'success'
  && window.location.pathname === pageData.reportComplete.url) return true;

  if (window.location.pathname === '/accessibility-statement') {
    return true;
  }

  const expectedUserRoute = buildUserPagesRoute(pageData);
  const storedRoute = sessionStorage.getItem(COMPLETED_PAGES_KEY);
  const actualUserRoute = storedRoute ? JSON.parse(storedRoute) : {};

  Object.keys(expectedUserRoute).forEach((index) => {
    if (!actualUserRoute[expectedUserRoute[index]]) {
      notValid = true;
    }
  });

  return !notValid;
};

/*
  ** The text property includes HTML
  ** this function will turn these tags into HTML
  ** and extract the text in these tags
*/
const textHasHtml = (text) => {
  const htmlText = text.split('>');
  const htmlTag = htmlText[0];
  switch (htmlTag) {
    case '<i':
      if (htmlText[1].includes('<')) {
        const index = htmlText[1].indexOf('<');
        htmlText[1] = htmlText[1].substring(0, index);
      }
      return (
        <i>{htmlText[1]}</i>
      );
    case '<b':
      if (htmlText[1].includes('<')) {
        const index = htmlText[1].indexOf('<');
        htmlText[1] = htmlText[1].substring(0, index);
      }
      return (
        <b>{htmlText[1]}</b>
      );
    default:
      return null;
  }
};

/*
  ** Function to create HTML
  ** Takes 2 args, tag and text.
  ** Tag will be the html tag <b> <br /> etc.
  ** Text will be any attributes or text for a bold tag for example
  ** Extra switch statement in <I> tag incase there is a tag inside like <i><b></b></i>
*/
const createHtml = (tag, text) => {
  let textArray;
  let attributes;
  if (text) {
    textArray = text.split('>');
    attributes = text.split("'");
  }
  let href;
  let target;
  let rel;
  // There are some cases where two of these tags are shown one after the other
  // and for some reason react gives us warnings in the console saying these need
  // keys - they're never going to be accessed by the key so just want to put something
  // random in the key param for each of these.
  switch (tag) {
    case '<br />':
      return (
        <br key={Math.random()} />
      );
    case '<b>':
      if (text.includes('<')) {
        return (
          <b key={Math.random()}>{textHasHtml(text)}</b>
        );
      }
      return (
        <b key={Math.random()}>{text}</b>
      );
    case '<a ':
      Object.keys(attributes).forEach((a1) => {
        if (attributes[a1 - 1] === 'href=') {
          href = attributes[a1];
        } else if (attributes[a1].includes('_blank')) {
          target = attributes[a1];
          rel = 'noreferrer';
        }
      });
      return (
        <a href={href} target={target} rel={rel} key={Math.random()}>{textArray[1]}</a>
      );
    case '<i>':
      if (text.includes('<')) {
        // separate if there is a child tag inside the parent tag <i><b> i.e.
        textHasHtml(text);
        return (
          <i key={Math.random()}>{textHasHtml(text)}</i>
        );
      }
      return (
        <i key={Math.random()}>{text}</i>
      );
    default:
      return null;
  }
};

/*
  Function to replace dangerouslySetInnerHtml
  Takes text and will split if <html> tag is found.
  Then splits the text into the html tag and text.
  Sends this to createHtml function
*/
const setInnerHtml = (text) => {
  if (text) {
    if (text.includes('<html>')) {
      const textArray = text.split('<html>');
      Object.keys(textArray).forEach((t) => {
        if (textArray[t].includes('<')) {
          let html;
          const endTag = textArray[t].substring(textArray[t].length - 3, textArray[t].length);
          if (endTag !== ' />') { // see if tag is an empty element or not
            const tag = textArray[t].substring(0, 3);
            textArray[t] = textArray[t].substring(3, textArray[t].length - 4);
            html = createHtml(tag, textArray[t]);
          } else {
            html = createHtml(textArray[t]);
          }
          textArray[t] = html;
        }
      });
      return textArray;
    }
    return text;
  }
  return null;
};

export const exportsForTesting = {
  checkRoute,
};

export {
  nextPageFinder,
  objectEqualityChecker,
  dynamicContent,
  buildUserPagesRoute,
  checkRoute,
  setInnerHtml,
  createHtml,
  textHasHtml,
};
