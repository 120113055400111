import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@ukncsc/my-ncsc-ui-common-core';
import CustomRow, { propTypes as customRowPropTypes } from './CustomRow';
import './CustomTable.css';

function CustomTable({ rows }) {
  if (Array.isArray(rows)) {
    return (
      <div className="table-divider">
        <Table className="Table">
          <tbody>
            {rows.map(({
              criteria, id, text, input, decider, change,
            }) => (
              <CustomRow
                id={id}
                key={id}
                text={text}
                input={input}
                decider={decider}
                change={change}
                criteria={criteria}
              />
            ))}
          </tbody>
        </Table>
      </div>
    );
  }

  return null;
}

export const propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape(customRowPropTypes)).isRequired,
};

CustomTable.propTypes = propTypes;

export default CustomTable;
