import {
  arrayOf, func, object, objectOf, string, oneOfType,
} from 'prop-types';
import React, { useEffect, useState } from 'react';
import { dynamicContent } from '../../../utils/pageUtil';
import ComponentMap from '../ComponentMap/ComponentMap';

const filterComponents = (components, values) => {
  const newComponents = [];
  Object.keys(components).forEach((component) => {
    if (component !== null && dynamicContent(components[component].criteria, values) === true) {
      newComponents.push(components[component].component);
    }
  });

  return newComponents;
};

function DynamicComponentMap({
  components, pageId, values, setValues, pageData,
}) {
  const [filtered, setFiltered] = useState(filterComponents(components, values));
  useEffect(() => {
    setFiltered(filterComponents(components, values));
  }, [components, values]);

  return (
    <ComponentMap
      components={filtered}
      pageId={pageId}
      values={values}
      setValues={setValues}
      pageData={pageData}
    />
  );
}

DynamicComponentMap.propTypes = {
  components: arrayOf(
    oneOfType([
      string,
      object,
    ]),
  ).isRequired,
  pageId: string.isRequired,
  values: objectOf(string).isRequired,
  setValues: func.isRequired,
  pageData: objectOf(
    oneOfType([
      string,
      object,
    ]),
  ).isRequired,
};

export const exportsForTesting = {
  filterComponents,
};

export default DynamicComponentMap;
