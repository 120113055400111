/**
 * replace variables in string with their values from session storage,
 * for example,
 * 'hello {star} {jurassic} {keanu}' => 'hello wars park reeves'
 */
const replaceVariablesNamesInString = (str) => {
  const regex = /{(.*?)}/g;
  let match;
  let result = str.slice(); // deep copy

  // eslint-disable-next-line no-cond-assign
  while (match = regex.exec(str)) {
    const [varWithBrackets, variableName] = match;
    const value = sessionStorage.getItem(variableName);
    /**
     * this is pretty horrible, but dollar signs are treated as a special character in regex
      * so we need to replace them with a double dollar sign (which then means adding in 4)
    */
    if (value) {
      const newValue = value.replace(/\$/g, '$$$$');
      result = result.replace(varWithBrackets, newValue);
    }
    result = result.replace(varWithBrackets, '');
  }
  return result;
};

const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

/// ///

const fetchJsonFromStorage = (unmappedName) => {
  const variableStop = unmappedName.indexOf('}');
  const variableName = unmappedName.substring(1, variableStop);
  const value = sessionStorage.getItem(variableName);
  if (value) {
    let parsed;
    if (isJson(value)) {
      parsed = JSON.parse(value);
    } else {
      parsed = value;
    }
    return parsed;
  }
  return false;
};

export const exportForTesting = { isJson, fetchJsonFromStorage };

export {
  replaceVariablesNamesInString,
  fetchJsonFromStorage,
};
