import Cookies from 'universal-cookie';
import ReactGA from 'react-ga4';
import { fetchVariable } from '../apiUtil/apiUtil';

const cookies = new Cookies();

const COOKIES_POLICY = 'cookie consent';

// get domain without subdomain part
export const getDomain = () => {
  if (window.location.hostname !== 'localhost') {
    const parts = window.location.hostname.split('.');
    parts.shift();
    return `.${parts.join('.')}`;
  }
  return window.location.hostname;
};

export function getCookiesPolicy() {
  return cookies.get(COOKIES_POLICY);
}

export function rejectGoogleAnalytics() {
  cookies.remove('_ga');
  cookies.remove('_gid');
  cookies.remove('_gat');
}

export function checkGoogleAnalyticsCookies() {
  const gaCookie = cookies.get('_ga');
  const gidCookie = cookies.get('_gid');
  return (gaCookie && gaCookie !== undefined
    && gidCookie && gidCookie !== undefined);
}

export async function acceptGoogleAnalytics() {
  await fetchVariable('analyticsProperty').then(async (response) => {
    if (response.ok) {
      ReactGA.initialize(await response.text());
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
    }
  });
}

export function setCookiesPolicy(policy) {
  cookies.set(COOKIES_POLICY, policy, { domain: getDomain() });
  if (policy) {
    acceptGoogleAnalytics();
  } else {
    rejectGoogleAnalytics();
  }
}
