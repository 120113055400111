import React, { useState } from 'react';
import {
  bool, func, number, objectOf, string,
} from 'prop-types';
import OrgPicker from '@imcapdev/organisation-picker';
import { orgManagerSearch } from '../../../../utils/apiUtil/apiUtil';
import './OrgSearcher.scss';

const setOrg = async (
  org,
  values,
  setValues,
  orgStorageId,
  identifierStorageId,
  setError,
  maxLength,
) => {
  setError(org.legalName.length > maxLength);
  setValues({
    ...values,
    [orgStorageId]: org.legalName,
    [identifierStorageId]: org.identifier,
  });
};

const orgPickerCSS = {
  organisation_picker__input_input: {
    width: '100%',
    padding: '0.375rem 0.75rem 0.375rem 0.75rem',
    borderRadius: '4px',
    border: 'none',
  },
  organisation_picker__input: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    border: 'none',
  },
};

function OrgSearcher({
  id,
  values,
  setValues,
  orgStorageId,
  identifierStorageId,
  hasError,
  maxLength,
}) {
  const [error, setError] = useState(false);
  return (
    <div
      key={id}
      role="none"
      className={`form-group ${hasError || error ? 'error' : ''}`}
      name="organisationName"
    >
      {(hasError || error) && (
        <span className="error_span" role="alert">
          Please enter organisation name using
          {' '}
          {maxLength}
          {' '}
          or less valid characters
          <br />
        </span>
      )}
      <label htmlFor='org-search-box__input' className={hasError || error ? 'error_margin' : ''}> {/* eslint-disable-line */}
        Organisation name (search)
      </label>
      <OrgPicker
        selectedValue={{
          identifier: values[identifierStorageId],
          legalName: values[orgStorageId],
        }}
        setOrg={(org) => {
          setOrg(org, values, setValues, orgStorageId, identifierStorageId, setError, maxLength);
        }}
        altSearchFunction={orgManagerSearch}
        theme={orgPickerCSS}
      />
    </div>
  );
}

OrgSearcher.propTypes = {
  id: string.isRequired,
  values: objectOf(string).isRequired,
  setValues: func.isRequired,
  orgStorageId: string.isRequired,
  identifierStorageId: string.isRequired,
  hasError: bool,
  maxLength: number,
};

OrgSearcher.defaultProps = {
  hasError: false,
  maxLength: 160,
};

export {
  setOrg,
};

export default OrgSearcher;
