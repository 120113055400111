import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import {
  arrayOf, bool, element, func, string,
} from 'prop-types';
import { Assets, DashboardLayout, MainCol } from '@ukncsc/my-ncsc-ui-common-core';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import Footer from '../PageComponents/Footer/Footer';
import {
  getCookiesPolicy,
  setCookiesPolicy,
} from '../../../utils/cookieUtil/cookieUtil';
import './Wrapper.scss';
import FeedbackBanner from '../PageComponents/FeedbackBanner/FeedbackBanner';

const STRING_COOKIE = {};

const acceptCookies = () => {
  setCookiesPolicy('true');
  window.location.reload();
};

const rejectCookies = () => {
  setCookiesPolicy('false');
  window.location.reload();
};

const cookiesAccepting = (setCookies) => {
  acceptCookies();
  setCookies(true);
};

const cookiesRejecting = (setCookies) => {
  rejectCookies();
  setCookies(true);
};

const goBack = (e) => {
  window.history.go(-1); e.preventDefault();
};

const showBackButton = (currentPath) => {
  const path = currentPath.toLowerCase();
  return path === '/' || path === '/serviceunavailable' || path === '/serviceproblem' || path === '/report-complete';
};

function BeforeContent(errorPage) {
  return (
    <div id="main" style={{ margin: '16px 0', width: 'fit-content' }}>
      {
        (!showBackButton(window.location.pathname) && !errorPage)
        && (
          <a href="/back" onClick={goBack} id="back-button">
            Back
          </a>
        )
      }
    </div>
  );
}

function scrollToTop() {
  const title = document.getElementById('ncsc-title');
  const page = document.getElementById('page');
  if (title) {
    title.scrollIntoView({ behavior: 'smooth', block: 'start' });
  } else if (page) {
    page.scrollIntoView({ behavior: 'smooth', block: 'start' });
    document.getElementById('top-of-page').focus();
  }
}

function CookieBanner({ cookies, setCookies }) {
  return (
    <div id="global-cookie-message" aria-label="cookie banner" className={!cookies ? 'show-banner' : 'no-banner'}>
      <div className="cookie-banner">
        <h2>Cookies on this site</h2>
        <p>We use some essential cookies to make this website work.</p>
        <p>
          We&#39;d like to set additional cookies to understand
          how you use our website so we can improve our services.
        </p>
        <div className="cookies-buttons">
          <button type="button" className="cookie-buttons" onClick={() => { cookiesAccepting(setCookies); }}>
            Accept optional cookies
            {STRING_COOKIE.accept}
          </button>
          <button type="button" className="cookie-buttons" onClick={() => { cookiesRejecting(setCookies); }}>
            Reject optional cookies
            {STRING_COOKIE.setPrefs}
          </button>
          <a href="https://www.ncsc.gov.uk/section/about-this-website/cookies" className="link" rel="noreferrer" target="_blank">Manage Cookies (opens in a new tab)</a>
        </div>
      </div>
    </div>
  );
}

function Header() {
  return (
    <header style={{ backgroundColor: '#041e52', color: 'white' }}>
      <div id="top-of-page" />
      <DashboardLayout>
        <div style={{ height: '50%' }}>
          <a href="https://www.ncsc.gov.uk/">
            <Assets.NCSCLogoFull color="white" className="header-logo" alt="NCSC - Home" />
          </a>
        </div>
        <hr style={{ width: '100%', size: '2px' }} />
        <div style={{ paddingBottom: '1em' }}>
          <h1 className="title" id="main-title" tabIndex={-1}>Report a Cyber Incident</h1>
        </div>
      </DashboardLayout>
    </header>
  );
}

export default function Wrapper({
  children, pageTitle,
}) {
  const [cookies, setCookies] = useState(false);
  const errorPage = pageTitle === 'Error';

  const path = useLocation();

  // check ga has been initiated, otherwise throws a warning out
  useEffect(() => {
    if (getCookiesPolicy() === 'true') {
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
    }
  }, [path]);

  return (
    <>
      <Helmet>
        <title id="ncsc-title">
          {pageTitle || 'Report a Cyber Incident'}
          {' '}
          - Report a Cyber Incident - NCSC
        </title>
      </Helmet>
      {!(getCookiesPolicy() && getCookiesPolicy().length !== 0)
        && (
          <CookieBanner
            cookies={cookies}
            setCookies={setCookies}
          />
        )}

      <div id="page">
        <div>
          <a className="skip-to-main" href="#main">Skip to main content</a>
          <header style={{ backgroundColor: '#041e52', color: 'white' }}>
            <div id="top-of-page" />
            <DashboardLayout>
              <div style={{ height: '50%' }}>
                <a href="https://www.ncsc.gov.uk/">
                  <Assets.NCSCLogoFull color="white" className="header-logo" alt="NCSC - Home" />
                </a>
              </div>
              <FeedbackBanner />
              <hr style={{ width: '100%', size: '2px' }} />
              <div style={{ paddingBottom: '1em' }}>
                <h1 className="title" id="main-title" tabIndex={-1}>Report a Cyber Incident</h1>
              </div>
            </DashboardLayout>
          </header>
          <div style={{ marginTop: '-80px' }}>
            <DashboardLayout>
              <MainCol style={{ backgroundColor: 'var(--c-body-bg)', padding: '0 20px' }}>
                {BeforeContent(errorPage)}
                <div className="content">
                  {children}
                </div>
              </MainCol>
            </DashboardLayout>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}

Wrapper.propTypes = {
  children: arrayOf(element).isRequired,
  pageTitle: string,
};

Wrapper.defaultProps = {
  pageTitle: 'Report a Cyber Incident',
};

CookieBanner.propTypes = {
  cookies: bool.isRequired,
  setCookies: func.isRequired,
};

export const exportsForTesting = {
  scrollToTop,
  showBackButton,
  CookieBanner,
  Header,
  cookiesAccepting,
  cookiesRejecting,
};
