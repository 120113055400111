/* eslint-disable import/no-cycle */
import React from 'react';
import {
  func, objectOf, string, object, arrayOf, oneOfType,
} from 'prop-types';
import BulletPoints from '../PageComponents/BulletPoints/BulletPoints';
import Information from '../PageComponents/Information/Information';
import Details from '../PageComponents/Details/Details';
import StartButton from '../PageComponents/StartButton/StartButton';
import Header from '../PageComponents/Header/Header';
import DropDown from '../PageComponents/DropDown/DropDown';
import TextBox from '../PageComponents/TextBox/TextBox';
import Textarea from '../PageComponents/TextArea/TextArea';
import RadioButton from '../PageComponents/RadioButtons/RadioButtons';
import OrgSearcher from '../PageComponents/OrgSearcher/OrgSearcher';
import CustomTable from '../PageComponents/Table/Custom/CustomTable';
import Card from '../PageComponents/Card/Card';
import SignpostingBanner from '../PageComponents/SignpostingBanner/SignpostingBanner';
import CheckBoxGroup from '../PageComponents/CheckBox/CheckBoxGroup';
import MultiTable from '../PageComponents/Table/Multi/MultiTable';
import ReferenceNumber from '../PageComponents/ReferenceNumber/ReferenceNumber';
import ArrayInput from '../PageComponents/ArrayInput/ArrayInput';
import Feedback from '../PageComponents/Feedback/Feedback';
import validationReg from '../../../utils/validationUtil/validationReg';

function ComponentMap({
  components, pageId, values, setValues, pageData,
}) {
  return (
    <>
      {
        components.map((component) => {
          const regObject = validationReg(component.regex, component.validationType);
          switch (component.type) {
            case 'signposting':
              return (
                <SignpostingBanner
                  id={component.id}
                  key={component.key}
                />
              );
            case 'card':
              return (
                <Card
                  key={component.key}
                  cardType={component.card_type}
                  header={component.header}
                  value={component.value}
                />
              );
            case 'text_input':
              return (
                <TextBox
                  id={component.key}
                  key={component.key}
                  storageId={component.storageId}
                  values={values}
                  setValues={setValues}
                  maxLength={component.maxLength}
                  label={component.label}
                  regex={regObject}
                  errorMessage={component.errorMessage}
                  required={component.required}
                  hasError={component.hasError}
                  helpText={component.helpText}
                />
              );
            case 'reference_number':
              return (
                <ReferenceNumber
                  input={component.value}
                  key={component.key}
                />
              );
            case 'break':
              return (
                <br key={component.key} />
              );
            case 'text_area':
              return (
                <Textarea
                  id={component.key}
                  key={component.key}
                  storageId={component.storageId}
                  values={values}
                  setValues={setValues}
                  maxLength={component.maxLength}
                  label={component.label}
                  helpText={component.helpText}
                  hasError={component.hasError}
                  required={component.required}
                  errorMessage={component.errorMessage}
                  regex={regObject}
                />
              );
            case 'dropdown':
              return (
                <DropDown
                  id={component.key}
                  key={component.key}
                  label={component.label}
                  options={component.options}
                  storageId={component.storageId}
                  values={values}
                  hasError={component.hasError}
                  setValues={setValues}
                  errorMessage={component.errorMessage}
                />
              );
            case 'bullet_points':
              return (
                <BulletPoints
                  key={component.key}
                  bulletPoints={component}
                />
              );
            case 'information':
              return (
                <Information
                  key={component.key}
                  text={component.value}
                />
              );
            case 'details':
              return (
                <Details
                  key={component.key}
                  component={component}
                />
              );
            case 'start_button':
              return (
                <StartButton
                  key={component.key}
                  component={component}
                  currentPageId={pageId}
                  pageData={pageData}
                />
              );
            case 'checkBoxGroup':
              return (
                <CheckBoxGroup
                  key={component.key}
                  options={component.options}
                  id={component.key}
                  values={values}
                  setValues={setValues}
                  hasError={component.hasError}
                  storageId={component.storageId}
                  errorMessage={component.errorMessage}
                />
              );
            case 'header':
              return (
                <Header
                  key={component.key}
                  text={component.text}
                  size={component.size}
                  bold={component.bold}
                />
              );
            case 'org_manager':
              return (
                <div key={component.key}>
                  <OrgSearcher
                    id={component.key}
                    orgStorageId={component.orgStorageId}
                    identifierStorageId={component.identifierStorageId}
                    values={values}
                    setValues={setValues}
                    hasError={component.hasError}
                    maxLength={component.maxLength}
                  />
                </div>
              );
            case 'radio_button':
              return (
                <RadioButton
                  id={component.key}
                  key={component.key}
                  storageId={component.storageId}
                  options={component.options}
                  values={values}
                  setValues={setValues}
                  direction={component.direction}
                  hasError={component.hasError}
                  errorMessage={component.errorMessage}
                  legend={component.legend}
                />
              );
            case 'customTable':
              return (
                <CustomTable
                  key={component.key}
                  rows={component.rows}
                />
              );
            case 'MultiTable':
              return (
                <MultiTable
                  key={component.key}
                  rows={component.rows}
                  sessionKey={component.session_key}
                />
              );
            case 'divider':
              return (
                <hr
                  key={component.key}
                  width="100%"
                  size="2px"
                  color="black"
                  style={{ marginTop: '2%', marginBottom: '2%' }}
                />
              );
            case 'array_input':
              return (
                <ArrayInput
                  id={component.key}
                  key={component.key}
                  storageId={component.storageId}
                  values={values}
                  setValues={setValues}
                  maxLength={component.maxLength}
                  numItems={component.numberOfItems}
                  label={component.label}
                  regex={regObject}
                  errorMessage={component.errorMessage}
                  required={component.required}
                  hasError={component.hasError}
                  helpText={component.helpText}
                />
              );
            case 'feedback':
              return <Feedback key={component.key} />;
            default:
              return null;
          }
        })
      }

    </>
  );
}

ComponentMap.propTypes = {
  components: arrayOf(
    oneOfType([
      string,
      object,
    ]),
  ).isRequired,
  pageId: string,
  values: objectOf(string),
  setValues: func,
  pageData: objectOf(
    oneOfType([
      string,
      object,
    ]),
  ),
};

ComponentMap.defaultProps = {
  pageData: undefined,
  values: undefined,
  setValues: undefined,
  pageId: undefined,
};

export default ComponentMap;
