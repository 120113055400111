import React, { useEffect, useState } from 'react';
import { fetchVariable } from '../../../../utils/apiUtil/apiUtil';
import './FeedbackBanner.scss';

export async function getFeedbackUrl(setFeedbackUrl) {
  await fetchVariable('feedbackUrl').then(async (response) => {
    if (response.ok) {
      setFeedbackUrl(await response.text());
    }
  });
}

function FeedbackBanner() {
  const [feedbackUrl, setFeedbackUrl] = useState('');
  useEffect(() => {
    getFeedbackUrl(setFeedbackUrl);
  }, []);
  if (feedbackUrl !== '') {
    return (
      <div className="feedback-banner">
        <p>
          <span>This is our new reporting site</span>
          Your
          {' '}
          <a href={feedbackUrl} target="_blank" rel="noreferrer">feedback</a>
          {' '}
          can help us improve it.
        </p>
      </div>
    );
  }
}

export default FeedbackBanner;
