import React, { useEffect, useState } from 'react';
import './footer.scss';
import { Assets, DashboardLayout, MainCol } from '@ukncsc/my-ncsc-ui-common-core';
import { useHistory } from 'react-router';
import { fetchVariable } from '../../../../utils/apiUtil/apiUtil';

export async function getFeedbackUrl(setFeedbackUrl) {
  await fetchVariable('feedbackUrl').then(async (response) => {
    if (response.ok) {
      setFeedbackUrl(await response.text());
    }
  });
}

function Footer() {
  const [feedbackUrl, setFeedbackUrl] = useState('');
  const history = useHistory();

  useEffect(() => {
    getFeedbackUrl(setFeedbackUrl);
  }, []);

  return (
    <footer style={{ backgroundColor: '#041e52', paddingBottom: '50px' }}>
      <DashboardLayout>
        <MainCol>
          <div className="footer-content">
            <a href="https://www.ncsc.gov.uk/">
              <Assets.NCSCLogoFull color="white" className="footer-logo" alt="NCSC - Home" />
            </a>
            <div style={{ color: 'white', listStyleType: 'none' }}>
              <p className="quick-links">QUICK LINKS</p>
              <ul className="footer-links" style={{ listStyleType: 'none' }}>
                <li><a href="https://www.ncsc.gov.uk/section/about-this-website/contact-us" className="footer-link">Contact Us</a></li>
                <li><a href="https://acdhub.service.ncsc.gov.uk/terms-and-conditions" className="footer-link">Terms and Conditions</a></li>
                <li>
                  <a
                    href="/accessibility-statement"
                    className="footer-link"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push('/accessibility-statement');
                    }}
                  >
                    Accessibility Statement
                  </a>
                </li>
                <li><a href="https://www.ncsc.gov.uk/section/about-this-website/cookies" className="footer-link">Cookies</a></li>
                {feedbackUrl !== '' && <li><a href={feedbackUrl} className="footer-link" target="_blank" rel="noreferrer">Provide Feedback</a></li>}
              </ul>
            </div>
          </div>
        </MainCol>
      </DashboardLayout>
    </footer>
  );
}

export default Footer;
