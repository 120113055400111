import React, { useEffect } from 'react';
import Title from '../../GenericComponents/PageComponents/Title/Title';
import Wrapper from '../../GenericComponents/Wrapper/Wrapper';
import './AccessibilityPage.scss';

function AccessibilityPage() {
  useEffect(() => {
    document.getElementById('main-title').focus();
  }, []);

  return (
    <Wrapper
      pageTitle="Accessibility Statement"
    >
      <Title
        title="Accessibility Statement For The National
          Cyber Security Centre’s Report An Incident Service"
      />
      <h3 className="h3-subheading">
        1. Overview
      </h3>
      <p>
        This accessibility statement applies to the
        Report an incident service for the NCSC Website.
      </p>
      <p>
        This service is run by the National Cyber Security Centre.
        The NCSC is committed to providing facilities and
        resources suitable for people with disabilities.
        For example, that means you should be able to:
      </p>
      <ul className="unordered-list">
        <li>zoom in up to 200% without the text spilling off the screen</li>
        <li>navigate all of the service using just a keyboard</li>
        <li>navigate all of the service using speech recognition software</li>
        <li>
          listen to all of the service using a screen reader
          (including the most recent versions of JAWS, NVDA and VoiceOver)
        </li>
      </ul>
      <p>
        We’ve also made the website text as simple as possible to understand.
        {' '}
        <a href="https://mcmw.abilitynet.org.uk/" target="_blank" rel="noreferrer">
          AbilityNet
        </a>
        {' '}
        has advice on making your device easier to use if you have a disability.
      </p>
      <h3 className="h3-subheading">
        2. How accessible this service is
      </h3>
      <p>We know some parts of this service are not fully accessible. For example:</p>

      <ul className="unordered-list">
        <li>Some elements are not fully accessible to screen reader software</li>
        <li>Some form elements are difficult to navigate using a keyboard</li>
      </ul>
      <h3 className="h3-subheading">
        3. What to do if you can’t access parts of this service
      </h3>
      <p>
        If you need information from this service in a different format, email
        {' '}
        <a href="mailto:enquiries@ncsc.gov.uk" target="_blank" rel="noreferrer">
          enquiries@ncsc.gov.uk
        </a>
      </p>
      <h3 className="h3-subheading">
        4. How to provide feedback or report accessibility problems with this service
      </h3>
      <p>
        We’re always looking to improve the accessibility of this service.
        If you find problems not listed on this page,
        think we’re not meeting accessibility requirements,
        or need information in a different format, please contact us. Email
        {' '}
        <a href="mailto:enquiries@ncsc.gov.uk" target="_blank" rel="noreferrer">
          enquiries@ncsc.gov.uk
        </a>
      </p>
      <h3 className="h3-subheading">
        5. Enforcement procedure
      </h3>
      <p>
        The Equality and Human Rights Commission (EHRC) is responsible for enforcing the
        Public Sector Bodies (Websites and Mobile Applications) (No. 2)
        Accessibility Regulations 2018 (the ‘accessibility regulations’).
        If you’re not happy with how we respond to your complaint,
        {' '}
        <a href="https://www.equalityadvisoryservice.com/" target="_blank" rel="noreferrer">
          contact the Equality Advisory and Support Service (EASS)
        </a>
        .
      </p>
      <h3 className="h3-subheading">
        6. Contacting us
      </h3>
      <p>
        You can send us an email using
        {' '}
        <a href="mailto:enquiries@ncsc.gov.uk" target="_blank" rel="noreferrer">
          enquiries@ncsc.gov.uk
        </a>
      </p>
      <h3 className="h3-subheading">
        7. Technical information about this website’s accessibility
      </h3>
      <p>
        The National Cyber Security Centre is committed to making its service accessible,
        in accordance with the Public Sector Bodies
        (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018.
      </p>
      <h4>
        Compliance status
      </h4>
      <p>
        This service is partially compliant with the
        {' '}
        <a href="https://www.w3.org/TR/WCAG21/" target="_blank" rel="noreferrer">
          Web Content Accessibility Guidelines version 2.1 AA standard
        </a>
        ,
        {' '}
        due to the non-compliances and exemptions listed below:
      </p>
      <ul className="unordered-list">
        <li>
          Radio button groups and checkbox groups can be difficult to
          navigate and understand effectively with a screen reader.
        </li>
      </ul>
      <h3 className="h3-subheading">
        8. Non-accessible content
      </h3>
      <p>
        The content listed below is non-accessible for the following reasons.
      </p>
      <h4>
        Non-compliance with the accessibility regulations
      </h4>
      <ul className="unordered-list">
        <li>
          Radio button groups and checkbox groups can be difficult to navigate
          and understand effectively with a screen reader.
          This fails WCAG 2.1 success criterion 4.1.1 (parsing).
          We plan to address this issue before October 2023,
          in a future project that will involve rebuilding how our online forms work.
        </li>
        <li>
          ‘Continue’ buttons navigate to the next section, this is behaviour that is not
          expected by screen readers, so new sections are announced by focus on heading to assist
          users with screen readers navigating. This still fails
          WCAG2.1 success criterion 2.4.4 (Link purpose).
          We plan to address this issue before October 2023, in a future project that will
          involve rebuilding how our online forms work.
        </li>
      </ul>
      <h4>
        Disproportionate burden
      </h4>
      <p>
        We’re not claiming a disproportionate burden to making any
        part of the service compliant to the accessibility regulations.
      </p>
      <h4>
        Content that’s not within the scope of the accessibility regulations
      </h4>
      <p>
        There is no content in the service that is
        outside the scope of the accessibility regulations.
      </p>
      <h3 className="h3-subheading">
        9. How we tested the service
      </h3>
      <p>
        This service was tested on 30th September 2022.
        The test was carried out by the NCSC. We tested all pages within the service.
      </p>
      <h3 className="h3-subheading">
        10. What we’re doing to improve accessibility
      </h3>
      <ul className="unordered-list">
        <li>
          We have improved the testing process so that accessibility problems are caught
          and resolved. This includes setting up an automated tool to monitor the site
          for accessibility issues, and incorporating an accessibility checklist into
          manual testing
        </li>
        <li>
          We will test new features and significant revisions as part of our development process,
          and we will audit the site for accessibility on an annual basis
        </li>
      </ul>
      <h3 className="h3-subheading">
        11. Preparation of this accessibility statement
      </h3>
      <p>
        This Accessibility statement was prepared on September 30th, 2022.
        This service was last tested on September 30th, 2022.
        The audit was carried out by the NCSC, on all pages.
      </p>
    </Wrapper>
  );
}

export default AccessibilityPage;
