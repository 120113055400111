import { nextPageFinder, updateCompletedUrls } from '../pageUtil';
import submitFormRequest from '../apiUtil/apiUtil';

const submitFormUtil = (
  currentPageId,
  pageData,
  history,
  setRecaptchaError,
  ReCaptcha,
) => async () => {
  let response;
  if (ReCaptcha || window.location.host === 'localhost:9999' || window.location.host === 'localhost:3000') {
    response = await submitFormRequest(ReCaptcha);
    if (response.status === 200) {
      if (sessionStorage.getItem('dev_test') === null) {
        sessionStorage.clear();
      }
      const body = await response.json();
      sessionStorage.setItem('reference_number', body.id);
      sessionStorage.setItem('form_submitted_status', 'success');
    } else if (response.status === 401) {
      sessionStorage.setItem('form_submitted_status', 'verification_failed');
      setRecaptchaError(true);
    } else {
      sessionStorage.setItem('form_submitted_status', 'failed');
    }

    updateCompletedUrls(currentPageId);
    const nextPage = nextPageFinder(pageData.pages[currentPageId].next);
    const link = pageData.pages[nextPage].url;

    history.push(link);
  } else {
    setRecaptchaError(true);
  }
};

export default submitFormUtil;
