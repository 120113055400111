import React from 'react';
import { Button } from '@ukncsc/my-ncsc-ui-common-core';
import { useHistory } from 'react-router-dom';
import {
  shape, string, object, oneOfType, array,
} from 'prop-types';
import { updateCompletedUrls } from '../../../../utils/pageUtil';

function StartButton({ component, currentPageId, pageData }) {
  const link = pageData.pages[component.nextPage].url;
  const history = useHistory();
  return (
    <Button
      id="submit_login"
      typeof="primary"
      onClick={
        () => {
          updateCompletedUrls(currentPageId);
          history.push(link);
        }
      }
      type="button"
      variant="dark"
    >
      <b style={{ fontWeight: '1000' }}>{component.text}</b>
    </Button>
  );
}

StartButton.propTypes = {
  component: shape({
    nextPage: string.isRequired,
  }).isRequired,
  currentPageId: string.isRequired,
  pageData: oneOfType([
    array,
    object,
  ]).isRequired,
};

export default StartButton;
