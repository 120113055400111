/* eslint-disable no-restricted-syntax */
import mapping from '../../../mapping_schema.json';

/**
 * replace variables in string with their values from session storage,
 * for example,
 * 'hello {star} {jurassic} {keanu}' => 'hello wars park reeves'.
 * If no value is found in session storage then return undefined
 */
const stringVariableMapper = (unmappedString) => {
  // return the same arg if nothing to replace
  if (typeof unmappedString !== 'string' || unmappedString.indexOf('{') === -1) {
    return unmappedString;
  }

  const regex = /{(.*?)}/g;
  let match;
  let result = unmappedString.slice(); // deep copy
  let resultIsUndefined = true;

  // eslint-disable-next-line no-cond-assign
  while (match = regex.exec(unmappedString)) {
    const [varWithBrackets, variableName] = match;
    const value = sessionStorage.getItem(variableName);
    if (value === null || value === undefined) {
      result = result.replace(varWithBrackets, '');
    } else {
      resultIsUndefined = false;
      /**
       * this is pretty horrible, but dollar signs are treated as a special character in regex
       * so we need to replace them with a double dollar sign (which then means adding in 4)
       */
      const newValue = value.replace(/\$/g, '$$$$');
      result = result.replace(varWithBrackets, newValue);
    }
  }

  return resultIsUndefined ? undefined : result;
};

const arrayVariableMapper = (unmappedString) => {
  // return the same arg if nothing to replace
  if (typeof unmappedString !== 'string' || unmappedString.indexOf('{') === -1) {
    return unmappedString;
  }

  const regex = /{(.*?)}/g;
  let match;
  let result;
  let resultIsUndefined = true;

  // eslint-disable-next-line no-cond-assign
  while (match = regex.exec(unmappedString)) {
    const [, variableName] = match;
    const value = sessionStorage.getItem(variableName);
    if (value === null) {
      return undefined;
    }
    resultIsUndefined = false;
    try {
      const parsed = JSON.parse(value);
      result = parsed.map((item) => item.value);
    } catch (e) {
      resultIsUndefined = true;
    }
  }

  return resultIsUndefined ? undefined : result;
};

const radioButtonSelector = (key, options) => {
  const sessionValue = sessionStorage.getItem(key.substring(1));
  if (sessionValue !== null) {
    const value = options[sessionValue];
    if (value !== undefined) {
      return stringVariableMapper(value);
    }
  }

  return stringVariableMapper(options['{DEFAULT}']);
};

/**
 * Check that at least one of the checkboxes has been selected, if
 * any have this will return true, else it will return false
 */
const anySelected = (checkboxes) => {
  const selected = Object.keys(checkboxes).filter((key) => checkboxes[key]);
  return selected.length > 0;
};

const checkBoxSelector = (key, options) => {
  const sessionValue = sessionStorage.getItem(key.substring(1));
  const parsed = JSON.parse(sessionValue);
  const checkedBoxes = Object.keys(parsed).filter((k) => parsed[k]);
  const result = [];

  if (!checkedBoxes.length) {
    return [options['{DEFAULT}']];
  }

  for (const boxKey of checkedBoxes) {
    const contents = options[boxKey];
    const contentType = typeof contents;

    if (contentType === 'object') {
      // eslint-disable-next-line no-use-before-define
      result.push(variableSetter(contents));
    } else if (contentType === 'string') {
      result.push(stringVariableMapper(contents));
    }
  }

  return result;
};

const checkBoxStringSelector = (key, options) => {
  const sessionValue = sessionStorage.getItem(key.substring(1));
  const parsed = JSON.parse(sessionValue);
  const checkedBoxes = Object.keys(parsed).filter((k) => parsed[k]);
  let result = '';

  if (!checkedBoxes.length) {
    return options['{DEFAULT}'];
  }

  for (const boxKey of checkedBoxes) {
    const contents = options[boxKey];
    result += stringVariableMapper(contents);
    result += ', ';
  }

  return result.slice(0, -2);
};

// function for checking a value is equal to something
const equalsCheck = (condition, value) => {
  // split the condition (check a key matches a value)
  let checkKey = '';
  let checkValue = '';
  [checkKey, checkValue] = condition.split('=');
  const mappedValue = stringVariableMapper(`{${checkKey}}`);

  // if they do not match, clear the value we send
  if (mappedValue === null || mappedValue === undefined
  || mappedValue.toString() !== checkValue) {
    return undefined;
  }
  return value;
};

// function for checking fields have been filled in
const notEmptyCheck = (condition, value) => {
  // remove the check type from the string
  const checkKey = condition.substring('[NOT_EMPTY]'.length);

  // map the value we want to check
  const mappedValue = stringVariableMapper(`{${checkKey}}`);
  let parsedValue = {};

  // as this is expected to be a stringified JSON we need to parse it
  try {
    parsedValue = JSON.parse(mappedValue);
  } catch (error) {
    parsedValue = mappedValue;
  }

  // check to see if there are any values selected
  if (!parsedValue || (typeof parsedValue === 'string' && mappedValue.length === 0)
        || (parsedValue && typeof parsedValue === 'object' && !anySelected(parsedValue))
        || (typeof parsedValue !== 'string' && typeof parsedValue !== 'object')) {
    return undefined;
  }
  return value;
};

// function for checking single requirements, and sending to the correct checker
const conditionChecker = (condition, value) => {
  if (condition.includes('=')) {
    return equalsCheck(condition, value);
  }
  if (condition.includes('[NOT_EMPTY]')) {
    return notEmptyCheck(condition, value);
  }
  return value;
};

// function for checking if 2 requirements must be correct
const andChecker = (condition, value) => {
  const checks = condition.split('&&');
  // newValue starts as value
  let newValue = value;

  // check that both are not undefined
  checks.forEach((check) => {
    // if either of the requirements fail, the value will be set as undefined
    if (conditionChecker(check, value) === undefined) newValue = undefined;
  });
  return newValue;
};

// function for checking values with multiple requirements
const orChecker = (condition, value) => {
  const checks = condition.split('||');
  // newValue starts as undefined
  let newValue;
  checks.forEach((check) => {
    // if any conditions match, update to expected value
    if (conditionChecker(check, value) !== undefined) newValue = value;
  });
  return newValue;
};

const formChecker = (condition, val) => {
  let value;
  if (condition.includes('||')) {
    value = orChecker(condition, val);
  } else if (condition.includes('&&')) {
    value = andChecker(condition, val);
  } else {
    value = conditionChecker(condition, val);
  }
  return value;
};

const arrayChecker = (result, value, key) => {
  const resultCopy = JSON.parse(JSON.stringify(result));
  const mapped = arrayVariableMapper(value[0]);
  if (mapped === undefined || mapped.length === 0) {
    delete resultCopy[key];
  } else {
    resultCopy[key] = mapped;
  }

  return resultCopy;
};

const stringChecker = (result, value, key) => {
  const resultCopy = JSON.parse(JSON.stringify(result));
  const mappedString = stringVariableMapper(value);
  if (mappedString === undefined) {
    delete resultCopy[key];
  } else {
    resultCopy[key] = mappedString;
  }

  return resultCopy;
};

/**
 * Recursively loop through JSON, replace variables in strings
 * with their values from session storage
 */
const variableSetter = (jsonObject) => {
  let result;
  try {
    result = JSON.parse(JSON.stringify(jsonObject));
  } catch (e) {
    return {};
  }
  for (let key of Object.keys(jsonObject)) {
    let value = jsonObject[key];
    let valueType = typeof value;

    switch (key.charAt(0)) {
      case '$':
        return radioButtonSelector(key, value);
      case '£':
        return checkBoxSelector(key, value);
      case '%':
        return checkBoxStringSelector(key, value);
      default:
        break;
    }
    // if the key includes a ';' it has a conditional check
    if (key.includes(';')) {
      // split up the key to give us the actual key and the condition we're checking
      const splitKey = key.split(';');
      let condition = '';
      let newKey = '';
      [newKey, condition] = splitKey;

      // delete the original key from the JSON object (we have the value parsed in already)
      delete result[key];

      // and now we no longer need the old key, we can swap it for the actual key part
      key = newKey;

      value = formChecker(condition, value);

      // otherwise, leave the value in tact and continue the loop using the new key.
      valueType = typeof value;
    }
    if (Array.isArray(value)) {
      result = arrayChecker(result, value, key);
    } else if (valueType === 'object') {
      result[key] = variableSetter(value);
    } else if (valueType === 'string') {
      result = stringChecker(result, value, key);
    }
  }

  return result;
};

// initialise variableSetter with mapping schema
const mappingUtil = () => variableSetter(mapping);

export default mappingUtil;

export const exportsForTesting = {
  radioButtonSelector,
  checkBoxSelector,
  checkBoxStringSelector,
  stringVariableMapper,
  anySelected,
  variableSetter,
  equalsCheck,
  notEmptyCheck,
  conditionChecker,
  orChecker,
  andChecker,
  formChecker,
  arrayChecker,
  stringChecker,
  arrayVariableMapper,
};
