import React from 'react';
import { Select } from '@ukncsc/my-ncsc-ui-common-core';
import '../hasError.css';
import './Dropdown.css';
import {
  arrayOf, bool, func, objectOf, shape, string,
} from 'prop-types';

function DropDown({
  id, options, label, storageId, values, setValues, hasError, errorMessage,
}) {
  return (
    <div className="dropDown">
      <div className={hasError ? 'error' : ''}>
        {hasError && (
          <span className="error_span" role="alert">
            {errorMessage}
            <br />
          </span>
        )}
        <Select
          id={id}
          key={id}
          autoComplete="on"
          onChange={(e) => setValues({ ...values, [storageId]: e })}
          onChanges={(e) => {
            setValues({ ...values, [storageId]: e });
          }}
          options={options}
          className="full-width auto-height"
          defaultValue={values[storageId]}
          fieldLabel={<span className={hasError ? 'error_margin form-label' : 'form-label'}>{label}</span>}
        />
      </div>
    </div>
  );
}

DropDown.propTypes = {
  id: string.isRequired,
  options: arrayOf(shape({
    value: string,
    id: string,
    text: string,
    label: string,
  })).isRequired,
  label: string || undefined,
  storageId: string.isRequired,
  values: objectOf(string).isRequired,
  setValues: func.isRequired,
  hasError: bool,
  errorMessage: string.isRequired,
};

DropDown.defaultProps = {
  label: undefined,
  hasError: false,
};

export default DropDown;
