/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useEffect, useState } from 'react';
import {
  object, string, oneOfType, objectOf,
} from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import { fetchVariable } from '../../../../utils/apiUtil/apiUtil';
import SubmitButton from '../SubmitButton/SubmitButton';
import './ReCaptcha.css';

// get recaptcha key from api
async function getKey(setRecaptchaKey, setRecaptchaError) {
  await fetchVariable('captchaPublicKey').then(async (response) => {
    if (response.ok) {
      setRecaptchaKey(await response.text());
    } else {
      throw response;
    }
  }).catch(
    () => {
      // for local testing, we still want the submit button displayed
      if (window.location.host === 'localhost:3000') setRecaptchaKey('localhost');
      else setRecaptchaError(true);
    },
  );
}

async function saveCaptcha(setRecaptchaError, setRecaptureValue, recaptchaRef) {
  setRecaptchaError(false);
  const captchaToken = await recaptchaRef.current.getValue();
  setRecaptureValue(captchaToken);
}

function ReCaptcha({ currentPageId, pageData }) {
  // NOTE: If the reCaptcha Key API is down error message will need updating.
  // Meeting to confirm next steps tbc
  const [reCaptchaValue, setRecaptureValue] = useState(null);
  const [recaptchaKey, setRecaptchaKey] = useState(null);
  const [reCaptchaError, setRecaptchaError] = useState(false);
  const recaptchaRef = useRef(null);
  useEffect(() => {
    getKey(setRecaptchaKey, setRecaptchaError);
  }, []);
  return (
    <div className="recaptcha">
      {reCaptchaError && (
      <div>
        <p>
          reCAPTCHA error, please try again.
          If the problem persists, please contact
          {' '}
          <a href="mailto:incidents@ncsc.gov.uk">incidents@ncsc.gov.uk</a>
          {' '}
          for further details
        </p>
      </div>
      // if the reCaptcha key is retrieved show the reCaptcha
      )}
      {recaptchaKey && (
      <div>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={recaptchaKey}
          className={(reCaptchaError ? 'recaptchaError' : null)}
          onChange={() => {
            saveCaptcha(
              setRecaptchaError,
              setRecaptureValue,
              recaptchaRef,
            );
          }}
          style={{ marginBottom: '1%', marginTop: '-2%' }}
        />
        <label hidden htmlFor="g-recaptcha-response">Recaptcha Response</label>
        <SubmitButton
          currentPageId={currentPageId}
          pageData={pageData}
          reCaptcha={reCaptchaValue}
          setRecaptchaError={setRecaptchaError}
        />
      </div>
      )}
    </div>

  );
}

ReCaptcha.propTypes = {
  currentPageId: string.isRequired,
  pageData: objectOf(
    oneOfType([
      string,
      object,
    ]),
  ).isRequired,
};

export const exportForTesting = { getKey, saveCaptcha };
export default ReCaptcha;
