import React from 'react';
import {
  object, string, arrayOf, oneOfType, objectOf, shape, array,
} from 'prop-types';
import { Tr, Td } from '@ukncsc/my-ncsc-ui-common-core';
import { replaceVariablesNamesInString } from '../../../../../utils/sessionUtil/sessionUtil';
import { dynamicContent } from '../../../../../utils/pageUtil';
import './CustomTable.css';

const toJson = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return false;
  }
};

function InputParser({ input, options }) {
  const json = toJson(input);
  if (!json) {
    return input;
  }
  // Numbers will parse as valid JSON, if the value is just a number
  // return a string explicitly.
  if (typeof json === 'number') {
    return `${input}`;
  }

  if (Array.isArray(json)) {
    return <ArrayInputList json={json} />;
  }

  return <SelectedCheckboxesList json={json} options={options} />;
}

InputParser.propTypes = {
  input: string.isRequired,
  options: oneOfType([
    shape({
      type: string,
      value: string,
    }),
    objectOf(objectOf(string)),
  ]),
};

InputParser.defaultProps = {
  options: undefined,
};

const SelectedCheckboxesList = ({ json, options }) => {
  const checkedCheckboxes = Object.keys(json).filter((k) => json[k]);
  return checkedCheckboxes.map((checkboxKey) => (
    <React.Fragment key={options[checkboxKey]}>
      <span>{options[checkboxKey]}</span>
      <br />
    </React.Fragment>
  ));
};

const ArrayInputList = ({ json }) => {
  let firstItem = true;
  return json.map((item) => {
    const returnValue = (
      <React.Fragment key={item.key}>
        {!firstItem && <br />}
        <span>{item.value}</span>
      </React.Fragment>
    );
    firstItem = false;
    return returnValue;
  });
};

function splitter(input, options) {
  return input.split(',').map((val) => (
    <span className="spanVal" key={val}>
      <InputParser input={replaceVariablesNamesInString(val)} options={options} />
      <br />
    </span>
  ));
}

function Row({
  leftText, middleText, change, id,
}) {
  return (
    <Tr className="Row">
      <Td className="left">{leftText}</Td>
      <Td id={`${id}_answer`} className="middle">{middleText}</Td>
      <Td className="right"><a href={change}>Change</a></Td>
    </Tr>
  );
}

Row.propTypes = {
  leftText: string.isRequired,
  middleText: oneOfType([string, array, object]),
  change: string.isRequired,
  id: string.isRequired,
};

Row.defaultProps = {
  middleText: undefined,
};

function CustomRow({
  id, input, criteria, decider = {}, text, change,
}) {
  const showRow = dynamicContent(criteria, sessionStorage);
  const valueFromSession = replaceVariablesNamesInString(input);

  if (!showRow) {
    return null;
  }

  if (decider.values) {
    const optionValue = replaceVariablesNamesInString(decider.options.value);
    const value = decider.values[optionValue];
    const split = splitter(input);
    const middle = (
      <>
        {split}
        {value}
      </>
    );
    return (
      <Row id={id} leftText={text} middleText={middle} change={change} />
    );
  }

  if (valueFromSession !== 'null') {
    const { options = {} } = decider;
    let middle;
    if (options[1]) {
      Object.keys(options).forEach((key) => {
        if (options[key].value === valueFromSession
          || valueFromSession.includes(options[key].text)) {
          middle = options[key].text;
        }
      });
    } else {
      middle = splitter(input, options);
    }
    return <Row id={id} leftText={text} middleText={middle} change={change} />;
  }

  return null;
}

export const propTypes = {
  id: string.isRequired,
  input: string.isRequired,
  criteria: arrayOf(
    oneOfType([
      string,
      object,
    ]),
  ).isRequired,
  decider: shape({
    variable_name: string,
    options: oneOfType([
      shape({
        type: string,
        value: string,
      }),
      objectOf(objectOf(string)),
    ]),
    values: objectOf(string),
    title: string,
  }),
  text: string.isRequired,
  change: string.isRequired,
};

export const defaultProps = {
  decider: {},
};

CustomRow.propTypes = propTypes;
CustomRow.defaultProps = defaultProps;

export const exportForTesting = {
  splitter,
  SelectedCheckboxesList,
  toJson,
  ArrayInputList,
  InputParser,
};
export default CustomRow;
