import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@ukncsc/my-ncsc-ui-common-core';
import {
  object, string, oneOfType, func, objectOf,
} from 'prop-types';
import submitForm from '../../../../utils/submitFormUtil/submitFormUtil';

function SubmitButton({
  currentPageId, pageData, reCaptcha, setRecaptchaError,
}) {
  const history = useHistory();
  const doSubmit = useCallback(
    submitForm(
      currentPageId,
      pageData,
      history,
      setRecaptchaError,
      reCaptcha,
    ),
    [currentPageId,
      pageData,
      history,
      reCaptcha,
      setRecaptchaError],
  );

  return (
    <Button
      id="submit_form"
      typeof="primary"
      onClick={doSubmit}
      type="button"
      variant="dark"
    >
      <b style={{ fontWeight: '1000' }}>Submit</b>
    </Button>
  );
}

SubmitButton.propTypes = {
  currentPageId: string.isRequired,
  pageData: objectOf(
    oneOfType([
      string,
      object,
    ]),
  ).isRequired,
  reCaptcha: string.isRequired,
  setRecaptchaError: func.isRequired,
};

export default SubmitButton;
