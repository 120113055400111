import React from 'react';
import { SummaryDetails } from '@ukncsc/my-ncsc-ui-common-core';
import {
  shape, string, object, oneOfType, arrayOf,
} from 'prop-types';
// eslint-disable-next-line import/no-cycle
import ComponentMap from '../../ComponentMap/ComponentMap';
import './Details.css';
import { setInnerHtml } from '../../../../utils/pageUtil';

function Details({ component }) {
  return (
    <div style={{ marginTop: '2.5%', marginBottom: '2.5%' }}>
      <SummaryDetails
        title={component.summary}
        content={
          typeof component.content !== 'string'
            ? <div className="details_border"><ComponentMap components={component.content} /></div>
            : <div className="details_border"><p>{setInnerHtml(component.content)}</p></div>
        }
      />
    </div>
  );
}

Details.propTypes = {
  component: shape({
    summary: string,
    content: oneOfType([string, arrayOf(object)]),
  }).isRequired,
};

export default Details;
