import React, { useState } from 'react';
import { TextArea } from '@ukncsc/my-ncsc-ui-common-core';
import {
  arrayOf, bool, func, number, objectOf, string,
} from 'prop-types';
import '../hasError.css';

const validation = (value, reg, maxL, setCharacterLimit, required) => {
  if (value.length === maxL) {
    setCharacterLimit(true);
  } else {
    setCharacterLimit(false);
    if (value === '' && required) {
      return true;
    }
  }
  return !value.match(reg);
};

function Textarea({
  id, storageId, values, setValues, maxLength = 500, label, helpText, hasError,
  regex, required, errorMessage,
}) {
  const [error, setError] = useState(null);
  const [characterLimit, setCharacterLimit] = useState(null);

  return (
    <div className={(hasError || characterLimit) || error ? 'error' : null}>
      <label htmlFor={id} className={(hasError || characterLimit) || error ? 'error_margin' : null}>{label}</label>
      {hasError || error ? <span className="error_span" role="alert">{errorMessage}</span> : null}
      {characterLimit && <div><span className="error_span" role="alert">You have reached the limit for characters in this field</span></div>}
      <TextArea
        id={id}
        key={id}
        autoComplete="on"
        handleChange={(e) => {
          const valid = validation(e, regex, maxLength, setCharacterLimit, required);
          if (valid) {
            setError(true);
          } else {
            setError(false);
          }
          setValues({ ...values, [storageId]: e });
        }}
        value={values[storageId]}
        maxLength={maxLength}
        helpText={helpText}
        className={(hasError || characterLimit) && 'error_margin'}
        pattern=""
      />
    </div>
  );
}

Textarea.propTypes = {
  id: string.isRequired,
  storageId: string.isRequired,
  values: objectOf(string).isRequired,
  setValues: func.isRequired,
  hasError: bool,
  errorMessage: string.isRequired,
  maxLength: number,
  label: string,
  helpText: string,
  regex: arrayOf(string).isRequired,
  required: bool,
};

Textarea.defaultProps = {
  hasError: false,
  maxLength: 500,
  label: '',
  helpText: '',
  required: false,
};

export const exportForTesting = { validation };
export default Textarea;
