import React, { useEffect, useState } from 'react';
import { TextInput, Icons } from '@ukncsc/my-ncsc-ui-common-core';
import './ArrayInput.scss';
import {
  string, objectOf, func, number, bool, arrayOf, shape, oneOfType, object, array,
} from 'prop-types';

function toJson(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return false;
  }
}

// Validation functions
const inputValidation = (value, regex, maxLength) => {
  if (!value.match(regex)) {
    return true;
  }
  if (value.length > maxLength) {
    return true;
  }
  return false;
};

const arrayValidation = (required, items, numItems) => {
  if (required && items.length === 0) {
    // if the array is required and contains no items
    return true;
  }
  if (items.length > numItems) {
    // if there are too many array items to send
    return true;
  }
  return false;
};

const validation = (value, regex, maxLength, required, items, numItems) => {
  const inputValid = inputValidation(value, regex, maxLength);
  const arrayValid = arrayValidation(required, items, numItems);

  return inputValid || arrayValid;
};

// search through to check if no duplicates before saving new item
const checkForDuplicates = (items, trimmed) => items.filter((item) => item.value === trimmed);

const saveNewValue = (
  currentInput,
  setArrayErrorMsg,
  setItems,
  setCurrentInput,
  regex,
  maxLength,
  items,
) => {
  const trimmed = currentInput.trim();
  if (trimmed === '') {
    setArrayErrorMsg('Cannot add a blank entry.');
    return;
  }
  if (inputValidation(trimmed, regex, maxLength)) {
    setArrayErrorMsg(`Please enter text using valid characters, up to ${maxLength} characters long.`);
    return;
  }
  if (checkForDuplicates(items, trimmed).length > 0) {
    setArrayErrorMsg('There is already an entry with the same value.');
    return;
  }
  setItems([...items, { key: crypto.randomUUID(), value: trimmed }]);
  setCurrentInput('');
  setArrayErrorMsg('');
};

// delete item from the array list
const deleteItem = (item, items, setItems) => {
  setItems(items.filter((current) => current.key !== item.key));
};

function InputList({
  items, setItems,
}) {
  return (
    <ul className="list">
      {
        items.map((item) => (
          <InputListItem
            key={item.key}
            item={item}
            deleteItem={deleteItem}
            items={items}
            setItems={setItems}
          />
        ))
      }
    </ul>
  );
}

function InputListItem({
  item, items, setItems,
}) {
  const { CrossIcon } = Icons;
  return (
    <li key={item.key}>
      <div className="input-group-confirmed">
        <input type="button" className="array-item" value={item.value} />
        <button type="button" className="btn" onClick={() => deleteItem(item, items, setItems)} id={`${item.value}_remove`}>
          <CrossIcon width="16" />
          Remove Item
        </button>
      </div>
    </li>
  );
}

function ArrayInput({
  id, storageId, values, setValues, maxLength = 100, numItems = 10, label, helpText, hasError,
  errorMessage, regex, required, autocompleteType,
}) {
  const [error, setError] = useState(null);
  const [arrayErrorMsg, setArrayErrorMsg] = useState('');
  const [currentInput, setCurrentInput] = useState('');
  const [items, setItems] = useState(toJson(values[storageId]) || []);
  const [itemsExceeded, setItemsExceeded] = useState(items.length >= numItems);

  useEffect(() => {
    setValues({ ...values, [storageId]: JSON.stringify(items) });
    setItemsExceeded(items.length >= numItems);
  }, [items]);

  const { AddIcon } = Icons;
  const errorStyle = hasError || error || arrayErrorMsg !== '' || itemsExceeded || currentInput.length >= maxLength;
  return (
    <div className={errorStyle ? 'error' : null}>
      <div className="arrayInput">
        <label htmlFor={id} className={errorStyle ? 'error_margin' : null}>{label}</label>
        <br />
        <p className={errorStyle ? 'helpText helpTextError' : 'helpText'}>{helpText}</p>
        {hasError || error ? <p className="error_span" role="alert">{errorMessage}</p> : null}
        {itemsExceeded && (
        <p className="error_span" role="alert">
          Limit reached. Only
          {' '}
          {numItems}
          {' '}
          entries allowed.
        </p>
        ) }
        {arrayErrorMsg !== '' && <p className="error_span" role="alert">{arrayErrorMsg}</p>}
        {currentInput.length >= maxLength && (
        <p className="error_span" role="alert">
          Only
          {' '}
          {maxLength}
          {' '}
          characters allowed.
        </p>
        )}
        <div className="input-group">
          <TextInput
            id={autocompleteType || id}
            key={id}
            name={autocompleteType || storageId}
            handleChange={(e) => {
              const valid = validation(e, regex, maxLength, required, items, numItems);
              if (valid) {
                setError(true);
              } else {
                setError(false);
              }
              setCurrentInput(e);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                saveNewValue(
                  currentInput,
                  setArrayErrorMsg,
                  setItems,
                  setCurrentInput,
                  regex,
                  maxLength,
                  items,
                );
              }
            }}
            autoComplete="on"
            hideCharacterCount
            value={currentInput}
            className={hasError && 'error_margin'}
            pattern=".*"
            maxLength={maxLength}
            disabled={itemsExceeded}
          />
          {
            (!itemsExceeded)
            && (
            <button
              type="button"
              value="Add"
              onClick={() => saveNewValue(
                currentInput,
                setArrayErrorMsg,
                setItems,
                setCurrentInput,
                regex,
                maxLength,
                items,
              )}
              id={`${storageId}_add`}
            >
              <AddIcon width="16" />
              Add
            </button>
            )
          }
        </div>
        <div className="array-list">
          <InputList
            items={items}
            deleteItem={deleteItem}
            setItems={setItems}
          />
        </div>
      </div>
    </div>
  );
}

const arrayInputPropTypes = {
  id: string.isRequired,
  storageId: string.isRequired,
  values: objectOf(string).isRequired,
  setValues: func.isRequired,
  maxLength: number,
  numItems: number,
  label: string.isRequired,
  helpText: string || undefined,
  hasError: bool,
  errorMessage: string.isRequired,
  regex: arrayOf(string).isRequired,
  required: bool,
  autocompleteType: string || null,
};

ArrayInput.propTypes = arrayInputPropTypes;

ArrayInput.defaultProps = {
  maxLength: 100,
  numItems: 10,
  hasError: false,
  required: false,
  autocompleteType: null,
  helpText: undefined,
};

InputList.propTypes = {
  items: arrayOf(shape({
    key: string.isRequired,
    value: string.isRequired,
  })),
  setItems: func.isRequired,
};

InputList.defaultProps = {
  items: [],
};

InputListItem.propTypes = {
  item: shape({
    key: string.isRequired,
    value: string.isRequired,
  }).isRequired,
  items: oneOfType([
    objectOf(
      oneOfType([
        string,
        object,
      ]),
    ),
    array,
  ]).isRequired,
  setItems: func.isRequired,
};

export const exportsForTesting = {
  validation,
  inputValidation,
  arrayValidation,
  saveNewValue,
  checkForDuplicates,
};

export default ArrayInput;
