/*
*  function to return regex, with the current regex value ,
  or literal if valid validationType passed
*/
const validationReg = function validationReg(regex, validationType) {
  let result;
  if (validationType != null && validationType === 'email') {
    /* email regex copied from ajv-formats npm */
    result = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
  } else {
    // continue with current regex value
    result = regex;
  }
  return result;
};

export default validationReg;
